<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addFieldValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.add_field") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col>
                            <validation-provider
                                :name="$t('general.field_name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='field_name'>{{ $t('general.field_name') }}</label>
                                <b-form-input id='field_name' v-model="field_name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BFormInput,  BButton} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required } from '@validations'
    export default {
        components: {BCol,
                     BRow,
                     BOverlay,
                     BCard,
                     BForm,
                     BFormInput,
                     BButton,
                     ValidationProvider,
                     ValidationObserver},
        data() {
            return {
                field_name: '',

                showLoader: false,
                required
            }
        },
        methods: {
            validationForm() {
                this.$refs.addFieldValidation.validate().then((response) => {
                    if (response) {
                        this.onAddField()
                    }
                })
            },
            onAddField() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Name: thisIns.field_name.charAt(0).toUpperCase() + thisIns.field_name.slice(1).toLowerCase()
                }

                thisIns.$http.put('/v1/admin/podrocja', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.field_successfully_added')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'field-list'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>